<template>
<div>
      <AdvanceTable ref="advanceTable" :url="apis.erpIncomingActualPayable" :height="window.height - 310" tableSize="sm" :columns="columns" :selectable="true" @row-selected="onRowSelected" v-loading="isLoading">
        <template #title>
            {{ $t("API Actual Payable") }}
        </template>
        <template #cell(unitPrice)="row">
          {{ row.item.unitPrice }} {{ row.item.currency }}
        </template>
        <template #cell(amount)="row">
          {{ row.item.amount }} {{ row.item.currency }}
        </template>
        <template #cell(addTime)="row">
          {{ formatWithCurrentTimezone(row.item.addTime) }}
        </template>
        <template #cell(selection)="row">
          <input type="checkbox" :checked="row.rowSelected" style="pointer-events: none"/>
        </template>
        <template #head(selection)="row">
          <input type="checkbox" style="margin-top: 40px" ref="selectAll" @click="onSelectAllSelected">
        </template>
      </AdvanceTable>
  </div>
</template>

<script>
import { getErpProfile } from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import { BButton, BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import { formatWithCurrentTimezone } from "./utils/time";

export default {
  components: {
    BButton, BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "selection", width: "30", align: "left"},
        { key: "customerName", modelName: "customerName", label: "Customer Name", width: "100", filtertype: "input", sortable: true },
        { key: "vendorName", modelName: "vendorName", label: "Vendor Name", width: "100", filtertype: "input", sortable: true },
        { key: "systemCode", modelName: "systemCode", label: "System Code", width: "100", filtertype: "input", sortable: true },
        { key: "accountCode", modelName: "accountCode", label: "Account Code", width: "100", filtertype: "input", sortable: true },
        { key: "billingNumber", modelName: "billingNumber", label: "Billing Number", width: "100", filtertype: "input", sortable: true },
        { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "80", filtertype: "fiscal", sortable: true },
        { key: "groupingId", modelName: "groupingId", label: "Grouping ID", width: "80", filtertype: "input", sortable: true },
        { key: "chargeCode", modelName: "chargeCode", label: "Charge Code", width: "100", filtertype: "input", sortable: true },
        { key: "warehouseCode", modelName: "warehouseCode", label: "W/H Code", width: "80", filtertype: "input", sortable: true },
        { key: "countryCode", modelName: "countryCode", label: "Country Code", width: "80", filtertype: "input", sortable: true },
        { key: "unitPrice", modelName: "unitPrice", label: "Unit Price", width: "50",  sortable: true },
        { key: "qty", modelName: "qty", label: "Qty", width: "50",  sortable: true },
        { key: "amount", modelName: "amount", label: "Total Amount", width: "100", sortable: true },
        { key: "description", modelName: "description", label: "Description", width: "100", filtertype: "input", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "80", filtertype: "select", options: { 'settled': 'Settled', 'billable': 'Billable'}, sortable: true, align: "center" },
        { key: "addTime", modelName: "addTime", label: "Add Time", width: "120" },
      ],
      isLoading: false,
    };
  },
  computed: {
    apis() {
      return apis
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {

  },
  methods: {
    formatWithCurrentTimezone,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    show(item) {
      this.$router.push({
        name: 'erp-company-profile',
        params: { id: item.organizationProfileId },
        hash: '#invoice'
      })
    },

    onRowSelected(items) {
      let accountCodes = [... new Set(items.map((item) => item.accountCode))];

      if (accountCodes.length > 1) {
        let newIds = items.map((item) => item.id);
        let oldIds = this.selected.map((item) => item.id);
        let diffIds = newIds.filter(n => !oldIds.includes(n));

        for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
          if (this.$refs.advanceTable.rows[rowIndex]['id'] === diffIds[0]) this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
        }

        return false;
      }

      this.selected = items;

      this.updateSelectAllState();
    },

    onSelectAllSelected() {
      if (this.$refs.selectAll.checked) {
        this.$refs.advanceTable.$refs.advanceTable.selectAllRows();
      } else {
        this.$refs.advanceTable.$refs.advanceTable.clearSelected();
      }
    },

    updateSelectAllState() {
      if (this.selected.length === 0) {
        this.$refs.selectAll.checked = false;
        this.$refs.selectAll.indeterminate = false;
      } else if (this.selected.length === this.$refs.advanceTable.rows.length) {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = false;
      } else {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = true;
      }
    }
  }
};
</script>

